import {
    AVAIL_CURRENCIES_FETCHED,
    BASE_CURRENCIES_FETCHED,
    CANCEL_REQUEST_FAILED,
    CANCEL_REQUEST_FETCHED,
    CHECKOUT_BUY_FAILED,
    CHECKOUT_BUY_REQUESTED,
    CHECKOUT_SELL_FAILED,
    CHECKOUT_SELL_REQUESTED,
    CURRENCIES_FETCHED,
    FETCHING_NETWORKS,
    FETCHING_NETWORKS_FAILED,
    FETCHING_ORDER,
    FETCHING_ORDER_INFO,
    FETCHING_TRANSFERS_LIST,
    NETWORKS_FETCHED,
    NEW_NETWORK,
    ORDER_FETCHED,
    ORDER_INFO_FAILED,
    ORDER_INFO_FETCHED,
    PRICE_UPDATE_SETTINGS_FETCHED,
    REDRAW_TRANSFERS_LIST,
    REQUESTING_CHECKOUT_BUY,
    REQUESTING_CHECKOUT_SELL,
    REQUESTING_ORDER_CANCEL,
    REQUESTING_SHOPPING_BUY,
    REQUESTING_SHOPPING_SELL,
    SHOPPING_BUY_FAILED,
    SHOPPING_BUY_REQUESTED,
    SHOPPING_SELL_FAILED,
    SHOPPING_SELL_REQUESTED,
    SUPPLIES_FETCHED,
    SWITCH_BUY_DIALOG,
    SWITCH_CANCEL_DIALOG,
    SWITCH_SELL_DIALOG,
    TRANSFERS_LIST_FETCHED,
    TRANSLATES_FETCHED,
    UPDATE_NETWORK,
} from "../constants/ActionTypes";
import {TRANSFER_TYPES} from "../panel/routes/data/constants";
import {global} from "../store/global";

const INIT_STATE = {
    currencies: {},
    supplies: {},
    initialAmount: 0,
    loadingCurrencies: true,
    loadingSupplies: true,
    transactions: [],
    totalTransactions: 0,
    loadingTransactions: false,
    buyDialogOpened: false,
    sellDialogOpened: false,
    cancelDialogOpened: false,
    selectedCurrency: '',
    selectedBase: '',
    requestingShopping: false,
    requestingOrderCancel: false,
    fetchingOrder: false,
    selectedOrder: {},
    fetchingNetworks: false,
    networks: [],
    gateway: {},
    gatewayUsed: false,
    cancelOrder: false,
    redraw: false,
    priceUpdatePeriod: 30000,
    lastPriceUpdateTime: 0,
    // addingCard: false,
    loadingBaseCurrencies: false,
    baseCurrencies: {},
    translates: {},
    loadingAvailableCurrencies: false,
    availableCurrencies: {},




};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PRICE_UPDATE_SETTINGS_FETCHED: {
            global.priceUpdatePeriod = action.payload.priceUpdatePeriod;
            global.lastPriceUpdateTime = action.payload.lastEmitTime;
            return {...state, priceUpdatePeriod: action.payload.priceUpdatePeriod, lastPriceUpdateTime: action.payload.lastEmitTime};
        }
        case FETCHING_TRANSFERS_LIST: {
            return {...state, loadingTransactions: true, redraw: false};
        }
        case TRANSFERS_LIST_FETCHED: {
            return {...state, loadingTransactions: false, redraw: false, transactions: action.payload.transactions ?? [], totalTransactions: action.payload.totalTransactions};
        }
        case BASE_CURRENCIES_FETCHED: {
            return {...state, loadingBaseCurrencies: false, baseCurrencies: action.payload};
        }
        case TRANSLATES_FETCHED: {
            return {...state, translates: action.payload.translates};
        }
        case AVAIL_CURRENCIES_FETCHED: {
            return {...state, loadingAvailableCurrencies: false, availableCurrencies: action.payload};
        }
        case CURRENCIES_FETCHED: {
            return {...state, loadingCurrencies: false, currencies: Object.assign({}, state.currencies, action.payload), lastPriceUpdateTime: parseInt(Date.now() / 1000)};
        }
        case SUPPLIES_FETCHED: {
            return {...state, loadingSupplies: false, supplies: action.payload};
        }
        case SWITCH_BUY_DIALOG: {
             global.selectedOrderID = null;
            return {...state, buyDialogOpened: action.payload.state, selectedOrder: {},
                selectedCurrency: action.payload.selectedCurrency, selectedBase: action.payload.selectedBase,
                initialAmount: action.payload.initialAmount ?? ''};
        }

        case SWITCH_SELL_DIALOG: {
             global.selectedOrderID = null;
            return {...state, sellDialogOpened: action.payload.state, selectedOrder: {},
                selectedCurrency: action.payload.selectedCurrency, selectedBase: action.payload.selectedBase,
                initialAmount: action.payload.initialAmount ?? ''};
        }
        case SWITCH_CANCEL_DIALOG: {
             global.selectedOrderID = null;
            return {...state, cancelDialogOpened: action.payload.state, selectedOrder: {}, cancelableID: action.payload.cancelableID};
        }
        case CANCEL_REQUEST_FETCHED: {
            return {...state, cancelOrder: true, redraw: true}
        }
        case CANCEL_REQUEST_FAILED: {
            return {...state, cancelOrder: false}
        }
        case REQUESTING_ORDER_CANCEL: {
            return {...state, requestingOrderCancel: true};
        }
        case REQUESTING_SHOPPING_BUY: {
            return {...state, requestingShopping: true};
        }
        case SHOPPING_BUY_REQUESTED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, requestingShopping: false, redraw: true, fetchingOrder: false, selectedOrder: action.payload.selectedOrder, gateway: action.payload.gateway, gatewayUsed: false};
        }
        case SHOPPING_BUY_FAILED: {
            return {...state, requestingShopping: false, gatewayUsed: false};
        }
        case REQUESTING_SHOPPING_SELL: {
            return {...state, requestingShopping: true};
        }
        case SHOPPING_SELL_REQUESTED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, requestingShopping: false, redraw: true, fetchingOrder: false, selectedOrder: action.payload.selectedOrder, gateway: action.payload.gateway, gatewayUsed: false,
                selectedCurrency: (action.payload.selectedOrder.type === TRANSFER_TYPES.BUY ? action.payload.selectedOrder.factor.d_currency : action.payload.selectedOrder.factor.o_currency)};
        }
        case SHOPPING_SELL_FAILED: {
            return {...state, requestingShopping: false, gatewayUsed: false};
        }
        case FETCHING_ORDER_INFO: {
            return {...state, fetchingOrder: true};
        }
        case ORDER_INFO_FETCHED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, fetchingOrder: false, selectedOrder: action.payload.selectedOrder, gateway: action.payload.gateway, gatewayUsed: false,
                selectedCurrency: (action.payload.selectedOrder.type === TRANSFER_TYPES.BUY ? action.payload.selectedOrder.factor.d_currency : action.payload.selectedOrder.factor.o_currency)};
        }
        case ORDER_INFO_FAILED: {
            return {...state, fetchingOrder: false};
        }
        case REQUESTING_CHECKOUT_BUY: {
            return {...state, requestingCheckout: true};
        }
        case CHECKOUT_BUY_REQUESTED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, requestingCheckout: false, redraw: true, fetchingOrder: false, selectedOrder: action.payload.selectedOrder};
        }
        case CHECKOUT_BUY_FAILED: {
            return {...state, requestingCheckout: false, gatewayUsed: false, selectedOrder: (action.payload.selectedOrder ?? state.selectedOrder)};
        }
        case REQUESTING_CHECKOUT_SELL: {
            return {...state, requestingCheckout: true};
        }
        case CHECKOUT_SELL_REQUESTED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, requestingCheckout: false, redraw: true, fetchingOrder: false, selectedOrder: action.payload.selectedOrder};
        }
        case CHECKOUT_SELL_FAILED: {
            return {...state, requestingCheckout: false, gatewayUsed: false};
        }
        case ORDER_FETCHED: {
            global.selectedOrderID = action.payload.selectedOrder.factor.id;
            return {...state, fetchingOrder: false, selectedOrder: action.payload.order};
        }
        case FETCHING_ORDER: {
            return {...state, fetchingOrder: true, selectedOrder: {}};
        }
        case FETCHING_NETWORKS: {
            return {...state, fetchingNetworks: true};
        }
        case NETWORKS_FETCHED: {
            return {...state, fetchingNetworks: false, networks: action.payload.networks};
        }
        case UPDATE_NETWORK: {
            const networks = [].concat(state.networks);
            networks.map((network, index) => {
                if(network.id === action.payload.id) {
                    networks[index] = Object.assign(networks[index], action.payload.network);
                }
            })
            return {...state, fetchingNetworks: false, networks: networks};
        }
        case NEW_NETWORK: {
            const networks = [].concat(state.networks).concat(action.payload.networks);
            return {...state, fetchingNetworks: false, networks: networks};
        }
        case FETCHING_NETWORKS_FAILED: {
            return {...state, fetchingNetworks: false, networks: []};
        }
        case REDRAW_TRANSFERS_LIST: {
            return {...state, redraw: true,};
        }
        // case CREDIT_CARD_ADDED: {
        //     return {...state, loadingCurrencies: false, cards: [...state.cards, action.payload]};
        // }


        default:
            return state;
    }
}

