import React from "react"
import Button from "@material-ui/core/Button";
import ImageStorage from "../../../assets/ImageStorage";


class HomeFooter extends React.Component{
    render(){
        return(
            <div className="home-footer-wrapper">
                <div className="home-footer">
                    <div className="links-wrapper">
                        <div className="links">
                            <div className="links-heading">
                                لینک های کاربردی
                            </div>
                            <a href='https://novinbit.xyz/account/signin/'>
                                <span>
                                    پنل قدیمی
                                </span>
                            </a>
                            <a href='https://novinbit.org/Privacy-Policy/'>
                                <span>
                                    حریم خصوصی
                                </span>
                            </a>
                            <a href='https://novinbit.org/AML-Policy/'>
                                <span>
                                    سیاست AML
                                </span>
                            </a>
                            <a href='https://novinbit.org/blog/'>
                            <span>
                                  مقالات
                            </span>
                            </a>
                        </div>
                       <div className="site-signs-wrapper">
                           {/*<Link to={""}*/}
                           {/*<a target={"_blank"} href={"https://iranblockchain.org/"}>*/}
                           {/*    <img alt="alt" src={require("../../../assets/images/signs/IranBlockChain-520-520-1-150x150.png")}/>*/}
                           {/*</a>*/}
                           <a target={"_blank"} href={"https://www.irannsr.org/"}>
                               <img alt="alt" src={require("../../../assets/images/signs/senf8.png")}/>
                           </a>
                           {/*<a target={"_blank"} href={"https://alpariforex.org/fa/registration?cpa_partner_id=12481708"}>*/}
                           {/*    <img alt="alt" className="little-img" src={require("../../../assets/images/signs/Alpari-563-460-300x245.png")}/>*/}

                           {/*</a>*/}
                           {/*<a target={"_blank"} href={"https://www.wmtransfer.com/"}>*/}
                           {/*    <img alt="alt" src={require("../../../assets/images/signs/bat135.png")}/>*/}

                           {/*</a>*/}
                           {/*/!*<a target={"_blank"} href={"https://perfectmoney.is/?ref=4356335"}>*!/*/}
                           {/*/!*    <img alt="alt" src={require("../../../assets/images/signs/Perfect-Gold-80-80-1.png")}/>*!/*/}
                           {/*/!*</a>*!/*/}
                           {/*<a target={"_blank"} href={"https://amarketstrading.co/cashback-persian/?g=NZH06"}>*/}
                           {/*    <img alt="alt" className="little-img" src={require("../../../assets/images/signs/Amarkets-563-460-1-300x245.png")}/>*/}
                           {/*</a>*/}

                                    </div>
                    </div>
                    <div className="about-us-wrapper">
                        <img alt="alt" src={require("../../../assets/images/common/FooterRedBorder.svg")}/>
                        <div className="about-us-inner">
                            <div className="heading">
                                درباره ما
                            </div>
                            <div className="description">
                                نوین بیت از سال 2010 برای مبادله لیبرتی‌رزرو، آلرت‌پی(پی‌زا)،  در قالب یک وبلاگ متولد شد و با هدف جلب رضایت مشتریان خود، در سال 2013 جهت حل مشکلات پرداخت‌های ارزی و دریافت درآمدهای به دست آمده از منابع خارج از کشور و کم‌رنگ نمودن تحریم‌های‌ اقتصادی علیه کشور، اقدام به راه‌اندازی وب‌سایت نوین بیت دات‌کام شامل خدمات مربوط به  آلرت‌پی(پی‌زا)، پی‌پال، ویزا/مسترکارت نمود. با توجه به نیاز بازار به یک سیستم مبادله لحظه‌ای و آنلاین، در سال 2018 اقدام به توسعه امکانات وب‌سایت و ارائه خدمات به صورت حرفه‌ای، با اتوماتیک نمودن خرید  پایر و تتر نموده و موفق به اخذ نمایندگی رسمی وب‌مانی و درجه آفیسر صدور پاسپورت وب‌مانی در ایران گردید. این مجموعه به عنوان یکی از اولین سکوهای تبادل ارز الکترونیکی در کشور بوده و در حال حاضر با پشتیبانی از صدها نوع رمزارز، یکی از سکوهای تبادل ارز دیجیتال در کشور نیز می‌باشد. نوین بیت همواره در تلاش است تا خدماتی به‌روز، شایسته و حرفه‌ای‌تر را به مشتریان خود ارائه دهد.                        </div>
                            <div className="buttons-wrapper">
                                <Button className="tether-btn" onClick={() => window.open('https://novinbit.org/buy-tether/')}>
                                    خرید و فروش تتر
                                </Button>
                                {/*<Button className="pm-btn" onClick={() => window.open('https://novinbit.org/buy-perfect-money/')}>*/}
                                {/*    خرید و فروش  پرفکت مانی*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                        <img alt="alt" src={require("../../../assets/images/common/FooterBlueBorder.svg")}/>
                    </div>
                    {/*<div className="social-media-wrapper">*/}
                    {/*    <div className="social-media">*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className="additional-info-wrapper">
                        <div className="additional-info">
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt" src={require("../../../assets/images/V2/footer-mail.svg")}/>
                                    <div className="title">
                                        ایمیل:
                                    </div>
                                </div>
                                <div className="desc">
                                    novinbitco@gmail.com
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt" src={require("../../../assets/images/V2/footer-work-time.svg")}/>
                                    <div className="title">
                                        ساعات پاسخگویی:
                                    </div>
                                </div>

                                <div className="desc">
                                    11 الی 17
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                <img alt="alt" src={require("../../../assets/images/V2/footer-support.svg")}/>
                                    <div className="title">
                                        پشتیبانی(چت و تیکت):
                                    </div>
                                </div>
                                <div className="desc">
                                    24/7
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt" src={require("../../../assets/images/V2/footer-phone.svg")}/>
                                    <div className="title">
                                        شماره تماس:
                                    </div>
                                </div>

                                <div className="desc">
                                    041-91012526
                                </div>
                            </div>
                        </div>
                        <div className="social-media-links-wrapper">
                            <a className="media-wrapper" href='https://www.instagram.com/novinbit/' target='_blank'>
                                {ImageStorage.miniSocialMedia.instagram()}

                            </a>
                            <a className="media-wrapper" href='https://t.me/novinbitco' target='_blank'>
                                {ImageStorage.miniSocialMedia.telegram()}

                            </a>
                            <a className="media-wrapper" href='https://www.youtube.com/playlist?list=PL-2KimLyZ0glAAz7PBqgvwgKmsiiypmv4' target='_blank'>
                                {ImageStorage.miniSocialMedia.youtube()}

                            </a>
                            <a className="media-wrapper" href='https://www.aparat.com/Novinbit_com' target='_blank'>
                                {ImageStorage.miniSocialMedia.aparat()}

                            </a>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                   <div className="footer-bottom-wrapper">
                       <div className="bottom-info">
                         {/*  <div className="info">
                               novinbitco@gmail.com

                           </div>*/}
                           {/*<div className="info">*/}
                           {/*    0919153832*/}

                           {/*</div>*/}
                           <div className="info">
                             تبریز، خ ارتش جنوبی، کوی فرشبافیان(2)،
                                بن‌بست بنفشه، پلاک 67
                               (از پذیرش حضوری افراد معذوریم).

                           </div>
                       </div>
                       <div className="gradient-bottom"/>
                   </div>
                    <div className="footer-copy-right">
                        Copyright: Novinbit.org© 2021

                    </div>
                </div>
            </div>

        )
    }
}
export default HomeFooter;