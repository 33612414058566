import React from "react"
import ReactStars from "react-rating-stars-component";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {
    closeTicket,
    rateTicket,
    switchChatBox,
    switchLiveChatContentStepper
} from "../../../../actions/SupportResource";
import {withStyles} from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = (theme) => ({

    card: {
        height: "100%",
        // '& .MuiCardContent-root': {
        //     padding: 0
        // }

    },
    button: {
        '& .MuiButton-label': {
            color: "unset !important"
        },
    },
    cardContentRoot: {

        display: "flex",
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        paddingBottom: "0px"
    },
    cardHeader: {
        padding: "8px"
    },

    starWrapper: {
        padding: "20px"
    },
    cardActionsRoot: {
        width: "100%",
        position: "absolute",
        bottom: "0"
    },
    headerContentWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'column',
        height: "calc(100% - 56px)",
        overflowY: "auto"
    }

});

class RateStep extends React.Component {

    constructor() {
        super();
        this.state = {
            rate: {}
        }
    }


    handleSubmit = (id) => {
        const {chatBoxSelectedTicket} = this.props;
        const {rate} = this.state
        this.props.rateTicket(chatBoxSelectedTicket.id, rate)
        this.props.switchChatBox(false)
        this.props.switchLiveChatContentStepper({stepIndex: 1})

    }
    handleClose = (e) => {
        const {chatBoxSelectedTicket} = this.props;
        this.props.closeTicket(chatBoxSelectedTicket.id)
        this.props.switchChatBox(false)
        this.props.switchLiveChatContentStepper({stepIndex: 1})
    }

    ratingChanged = (newRating) => {
        this.setState({rate: newRating})
    };


    render() {

        const {classes, submittingRate} = this.props
        return (
            <div className="rate-step-wrapper">
                <div className="support-chats-wrapper">
                    <div className="d-flex flex-nowrap chat-item flex-row-reverse">
                        <img alt="alt" className="chat-profile"
                             src={require("../../../../assets/images/header/userHeader.svg")}
                        />
                        <div className="chat-bubble admin-chat">
                            <div className="chat-message" style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>
                                کاربر گرامی ؛
                                <br/>
                                با تشکر از حسن انتخاب شما لطفا در این مرحله با امتیازدهی به بخش پشتیبانی وبسایت نوین بیت مارا در بهبود فرآیند پشتیبانی یاری نمایید.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="support-rate-section">
                    <div className="rate-desc">
                        کاربر گرامی با لمس یا کلیک بر روی هر ستاره به ترتیب از چپ به راست امتیاز خود را مشخص و با کلیک
                        بر دکمه ی ثبت امتیاز؛ امتیاز خود را ثبت نمایید.

                    </div>
                    <div className="rate-stars">
                        <ReactStars a11y={true} classNames={classes.starWrapper}
                                    emptyIcon={<StarIcon/>}
                                    edit={true}
                                    color={"#999999"}
                                    isHalf={false}
                                    count={5}
                                    onChange={this.ratingChanged}
                            // size={60}
                                    activeColor="#ffd700"
                                    filledIcon={<StarIcon/>}
                            //         emptyIcon = {<StarIcon className="jr-fs-xxl"/>}
                            // filledIcon = {<StarIcon className="jr-fs-xxl"/>}
                        />
                    </div>
                </div>
                <div className="buttons-wrapper">
                    <Button size={"large"}
                            variant="contained"
                            disabled={submittingRate}
                            onClick={this.handleSubmit}
                            fullWidth
                            className="confirm-btn"
                    >

                        {submittingRate ? <div className="sending-btn-text"><IntlMessages id="button.submitting"/> <CircularProgress size={16} color={"inherit"}/></div> :
                            <IntlMessages id="button.submit_rate"/>}
                    </Button>
                    <Button size={"large"}
                            variant="contained"
                            disabled={submittingRate}
                            onClick={this.handleClose}
                            fullWidth
                            className="close-btn"
                    >
                        <IntlMessages id={"button.close"}/>
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({support}) => {
    const {liveChatWrapperOpened, stepIndex, chatBoxSelectedTicket, submittingRate} = support;
    return {liveChatWrapperOpened, stepIndex, chatBoxSelectedTicket, submittingRate}
};
export default connect(mapStateToProps, {
    switchChatBox,
    switchLiveChatContentStepper,
    rateTicket,
    closeTicket

})(withStyles(styles)(RateStep));
