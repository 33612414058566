import {
    FETCHING_LAST_LIVE_CHAT,
    FETCHING_TICKET_MESSAGES,
    FETCHING_TICKETS_LIST,
    LAST_LIVE_CHAT_FAILED,
    LAST_LIVE_CHAT_FETCHED,
    MESSAGE_SUBMIT_FAILED,
    NEW_CHATBOX_MESSAGE,
    NEW_TICKET,
    NEW_TICKET_MESSAGE,
    RATING_TICKET,
    SET_CHAT_CONVERSATION_INFORMATION,
    START_CHATBOX_TICKET,
    SUBMITTING_TICKET,
    SUBMITTING_TICKET_MESSAGE,
    SWITCH_CHATBOX,
    SWITCH_COMPOSE_DIALOG,
    SWITCH_LIVE_CHAT_CONTENT_STEPPER,
    SWITCH_TICKET_DRAWER,
    TICKET_CLOSED,
    TICKET_MESSAGE_SUBMITTED,
    TICKET_MESSAGES_FETCHED,
    TICKET_RATED,
    TICKET_SEEN,
    TICKET_SUBMIT_FAILED,
    TICKET_SUBMITTED,
    TICKET_TYPING,
    TICKETS_LIST_FETCHED,
    UNSELECT_TICKET,
    UPLOAD_PROGRESS
} from "../constants/ActionTypes";

import {global} from "../store/global";
import {STATUS, TICKET_SHOW_TYPES} from "../panel/routes/data/constants";
import {ticketSeen} from "../util/Socket";

const INIT_STATE = {
    tickets: [],
    totalTickets: 0,
    loadingTickets: false,
    submittingTicket: false,
    selectedTicket: {},

    messages: [],
    totalMessages: 0,
    loadingMessages: false,
    submittingMessage: false,
    ticketsRedraw: false,
    messagesRedraw: false,
    progress: 0,
    stats: {},
    composeDialogOpened: false,
    drawerOpened: false,
    ticketSubmitted: false,
    liveChatWrapperOpened: false,
    stepIndex: 1,
    fetchingChatboxTicket: false,
    chatBoxSelectedTicket: {},
    chatBoxMessages: [],
    submittingRate: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case START_CHATBOX_TICKET: {
            const {title, management_unit} = action.payload;
            return {...state, stepIndex: 2, chatBoxMessages: [], chatBoxSelectedTicket: {title , management_unit}}
        }

        case NEW_CHATBOX_MESSAGE: {
            const chatboxMessages = state.chatBoxMessages.splice();
            chatboxMessages.push(action.payload.message);
            return {...state, chatboxMessages}
        }

        case UNSELECT_TICKET: {
            global.selectedTicket = null;
            return {...state, selectedTicket: {} };
        }
        case UPLOAD_PROGRESS: {
            return {...state, progress: action.payload.progress };
        }
        case FETCHING_TICKETS_LIST: {
            return {...state, loadingTickets: true, ticketsRedraw: false};
        }
        case SUBMITTING_TICKET: {
            return {...state, submittingTicket: true, ticketSubmitted: false};
        }
        case FETCHING_TICKET_MESSAGES: {
            return {...state, loadingMessages: true, messagesRedraw: false, selectedTicket: {}};
        }
        case SUBMITTING_TICKET_MESSAGE: {
            return {...state, submittingMessage: true, ticketSubmitted: false};
        }
        case TICKET_SUBMITTED: {
            const tickets = state.tickets.slice();
            const newTicket = action.payload.ticket;
            tickets.unshift(newTicket);
            const updatable = {...state,composeDialogOpened: false, submittingTicket: false, ticketsRedraw: false, ticketSubmitted: true,
                tickets: tickets, messagesRedraw: true};
            if(newTicket.show_type == TICKET_SHOW_TYPES.CHATBOX) {
                const newMessage = action.payload.message;
                updatable.chatBoxSelectedTicket = newTicket;
                updatable.chatBoxMessages = [newMessage];
            } else {
                updatable.selectedTicket = newTicket;
            }


            return updatable;
        }
        case TICKET_SUBMIT_FAILED: {
            return {...state, submittingTicket: false, composeDialogOpened: false};
        }
        case FETCHING_LAST_LIVE_CHAT: {
            return {...state, fetchingChatboxTicket: true};
        }
        case LAST_LIVE_CHAT_FETCHED: {
            const ticket = action.payload.ticket;
            return {...state, fetchingChatboxTicket: false, stepIndex: (ticket.id ? 2 : state.stepIndex),
                chatBoxSelectedTicket: ticket, chatBoxMessages: action.payload.messages};
        }
        case LAST_LIVE_CHAT_FAILED: {
            return {...state, fetchingChatboxTicket: false};
        }
        case TICKET_CLOSED: {
            const tickets = state.tickets.slice();
            const status = action.payload.status;
            let selectedTicket = state.selectedTicket;
            let chatBoxSelectedTicket = state.chatBoxSelectedTicket;
            tickets.map((ticket, index) => {
                if(ticket.id === action.payload.ticket_id)
                    tickets[index].status = status;
            })
            const updatable = {...state, tickets: tickets};
            if(status === STATUS.REJECTED_BY_ADMIN) {
                if(selectedTicket && selectedTicket.id === action.payload.ticket_id) {
                    selectedTicket = Object.assign({}, state.selectedTicket);
                    selectedTicket.status = status;
                    updatable.selectedTicket = selectedTicket;
                }
                if(chatBoxSelectedTicket && chatBoxSelectedTicket.id === action.payload.ticket_id) {
                    chatBoxSelectedTicket = Object.assign({}, state.chatBoxSelectedTicket);
                    chatBoxSelectedTicket.status = status;
                    updatable.chatBoxSelectedTicket = chatBoxSelectedTicket;
                }
            } else if(status === STATUS.REJECTED_BY_USER) {
                selectedTicket = Object.assign({}, state.selectedTicket);
                selectedTicket = {};
                updatable.selectedTicket = selectedTicket;
            }
            return updatable
        }
        case TICKET_SEEN: {
            const updatable = {...state};
            const seen = action.payload.seen;
            let selectedTicket = state.selectedTicket;
            let chatBoxSelectedTicket = state.chatBoxSelectedTicket;
            if(selectedTicket && selectedTicket.id == action.payload.ticket_id) {
                const messages = state.messages.slice();
                messages.map((message, index) => {
                    messages[index].seen = seen;
                });
                updatable.messages = messages;
            }

            if(chatBoxSelectedTicket && chatBoxSelectedTicket.id == action.payload.ticket_id) {
                const chatBoxMessages = state.chatBoxMessages.slice();
                chatBoxMessages.map((message, index) => {
                    chatBoxMessages[index].seen = seen;
                });
                updatable.chatBoxMessages = chatBoxMessages;
            }
            return updatable;
        }
        case TICKET_TYPING: {
            const now = (new Date().getTime() / 1000);
            const updatable = {...state};
            let selectedTicket = state.selectedTicket;
            if(selectedTicket && selectedTicket.id == action.payload.ticket_id) {
                selectedTicket = Object.assign({}, state.selectedTicket)
                selectedTicket.isAdminTyping = true;
                selectedTicket.adminTypingTime = now;
                updatable.selectedTicket = selectedTicket;
            }
            let chatBoxSelectedTicket = state.chatBoxSelectedTicket;
            if(chatBoxSelectedTicket && chatBoxSelectedTicket.id == action.payload.ticket_id) {
                chatBoxSelectedTicket = Object.assign({}, state.chatBoxSelectedTicket)
                chatBoxSelectedTicket.isAdminTyping = true;
                chatBoxSelectedTicket.adminTypingTime = now;
                updatable.selectedTicket = chatBoxSelectedTicket;
            }
            return updatable;
        }
        case NEW_TICKET: {
            const tickets = state.tickets.slice();
            const newTicket = action.payload.ticket;
            const newTicketID = action.payload.ticket_id;
            const ticketExists = tickets.some((ticket) => ticket.id == newTicketID);
            if(!ticketExists)
                tickets.unshift(newTicket);
            return {...state, tickets: tickets}
        }
        case NEW_TICKET_MESSAGE: {
            const messages = state.messages.slice();
            const tickets = state.tickets.slice();
            const newMessage = action.payload.message;
            const ticketID = action.payload.ticket_id;
            let selectedTicket = state.selectedTicket;
            let chatBoxSelectedTicket = state.chatBoxSelectedTicket;
            if(selectedTicket && selectedTicket.id === ticketID) {
                selectedTicket = Object.assign({}, state.selectedTicket);
                messages.push(newMessage);
                selectedTicket.status = STATUS.USER_PROGRESS
                selectedTicket.isAdminTyping = false;
                ticketSeen(ticketID);
            }

            tickets.map((ticket, index) => {
                if(ticket.id == ticketID) {
                    if(!selectedTicket || selectedTicket.id !== ticketID)
                        tickets[index].unreads++;
                    tickets[index].status = STATUS.USER_PROGRESS;

                }
            })
            const updatable = {...state, messages: messages, tickets: tickets};
            if(chatBoxSelectedTicket && chatBoxSelectedTicket.id === ticketID) {
                chatBoxSelectedTicket = Object.assign({}, chatBoxSelectedTicket);
                const chatBoxMessages = state.chatBoxMessages.slice();
                chatBoxMessages.push(newMessage);
                chatBoxSelectedTicket.status = STATUS.USER_PROGRESS
                chatBoxSelectedTicket.isAdminTyping = false;
                ticketSeen(ticketID);
                updatable.chatBoxSelectedTicket = chatBoxSelectedTicket;
                updatable.chatBoxMessages = chatBoxMessages;
            }
            return updatable;
        }
        case TICKETS_LIST_FETCHED: {
            return {...state, loadingTickets: false, ticketsRedraw: false, tickets: action.payload.tickets ?? [], totalTickets: action.payload.totalTickets, stats: action.payload.stats};
        }
        case TICKET_MESSAGES_FETCHED: {
            global.selectedTicket = action.payload.selectedTicket;
            let tickets = [];
            state.tickets.map((ticket) => {
                if(ticket.id === action.payload.selectedTicket.id)
                    ticket.unreads = 0;
                tickets.push(ticket);
            })
            return {...state, loadingMessages: false, messagesRedraw: false, drawerOpened: false, tickets: tickets, selectedTicket: action.payload.selectedTicket ,messages: action.payload.messages ?? [], totalMessages: action.payload.totalMessages};
        }
        case TICKET_MESSAGE_SUBMITTED: {
            const messages = state.messages.slice();
            const tickets = state.tickets.slice();
            const newMessage = action.payload.message;
            const ticketData = action.payload.ticket;
            let selectedTicket = Object.assign({}, state.selectedTicket);
            let chatBoxSelectedTicket = state.chatBoxSelectedTicket;
            if(selectedTicket && selectedTicket.id == ticketData.id) {
                selectedTicket = Object.assign(state.selectedTicket, ticketData);
                messages.push(newMessage);
            }
            tickets.map((ticket, index) => {
                if(ticket.id == ticketData.id)
                    tickets[index] = Object.assign(ticket, ticketData);
            })
            const updatable = {...state, submittingMessage: false, messagesRedraw: false, ticketSubmitted: true,
                messages: messages, tickets: tickets, selectedTicket: selectedTicket};
            if(chatBoxSelectedTicket && chatBoxSelectedTicket.id == ticketData.id) {
                chatBoxSelectedTicket = Object.assign(state.chatBoxSelectedTicket, ticketData);
                const chatBoxMessages = state.chatBoxMessages.slice();
                chatBoxMessages.push(newMessage);
                updatable.chatBoxSelectedTicket = chatBoxSelectedTicket;
                updatable.chatBoxMessages = chatBoxMessages;
            }
            return updatable;
        }
        case RATING_TICKET: {
            return {...state, submittingRate: true}
        }
        case TICKET_RATED: {
            return {...state, submittingRate: false}
        }
        case MESSAGE_SUBMIT_FAILED: {
            return {...state, submittingMessage: false, messagesRedraw: false};
        }
        case SWITCH_COMPOSE_DIALOG: {
            return {...state, composeDialogOpened: action.payload.state, drawerOpened: (action.payload.state ? false : state.drawerOpened)};
        }
        case SWITCH_TICKET_DRAWER: {
            return {...state, drawerOpened: action.payload.state};
        }
        case SWITCH_CHATBOX: {
            return {...state, liveChatWrapperOpened: action.payload.state};
        }
        case SWITCH_LIVE_CHAT_CONTENT_STEPPER: {
            return {...state, stepIndex: action.payload.stepIndex};
        }
        case SET_CHAT_CONVERSATION_INFORMATION: {
            return {...state, chatSubject: action.payload.chatSubject, chatManagementUnit: action.payload.chatManagementUnit}
        }

        default:
            return state;
    }
}

